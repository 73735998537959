import React, { useEffect, useState } from "react";
import "./header.css";
import imgLogo from "../../assets/favicon/znet.jpg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const Header = () => {
    const [userName, setUserName] = useState(null);
    const [categories, setCategories] = useState([]); // To store categories
    const [loadingSubCategories, setLoadingSubCategories] = useState({}); // Loading state for subcategories
    const [subCategories, setSubCategories] = useState({}); // Store subcategories based on category ID
    const navigate = useNavigate();
    const location = useLocation();
    const [userRole, setUserRole] = useState(1); // Default to 'Guest'


    useEffect(() => {

        const storedUserName = localStorage.getItem("user_name");
        const storedUserEmail = localStorage.getItem("user_email");

        
        if (storedUserName) {
            setUserName(storedUserName);
        } else if (storedUserEmail) {
            const emailNamePart = storedUserEmail.split("@")[0];
            setUserName(emailNamePart);
        } else {
            setUserName(null);
        }

        const storedUserRole = localStorage.getItem("roles");
        if (storedUserRole) {
            const cleanedRole = storedUserRole.replace(/"/g, '');
            const roleInt = parseInt(cleanedRole, 10);
            if (!isNaN(roleInt)) {
                setUserRole(roleInt);
            } else {
                setUserRole(null);
            }
        }
    }, [location]);  // Make sure 'location' is defined in your component or remove it if not used.


    const isAuthenticated = !!userName;

    const handleLogout = () => {
        localStorage.removeItem("user_email");
        localStorage.removeItem("user_id");
        localStorage.removeItem("user_name"); // Ensure this is also cleared
        localStorage.removeItem("token"); // If you use token
        setUserName(null);
        navigate("/login");
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch("https://api.biznetusa.com/api/get-category");
                if (!response.ok) {
                    throw new Error("Failed to fetch categories");
                }
                const data = await response.json();
                setCategories(data.categories || []); // Set categories, ensure it's an array
            } catch (error) {
            }
        };

        fetchCategories();
    }, []);

    const fetchSubCategories = async (categoryId) => {
        try {
            setLoadingSubCategories((prevState) => ({
                ...prevState,
                [categoryId]: true,
            }));
            const response = await fetch(
                `https://api.biznetusa.com/api/subcategories/${categoryId}`
            );
            if (!response.ok) {
                throw new Error("Failed to fetch subcategories");
            }
            const data = await response.json();
            setSubCategories((prevState) => ({
                ...prevState,
                [categoryId]: data.categories[0].sub_categories,
            }));
        } catch (error) {
        } finally {
            setLoadingSubCategories((prevState) => ({
                ...prevState,
                [categoryId]: false,
            }));
        }
    };

    // const [userRole, setUserRole] = useState(null);
    // useEffect(() => {
    //     const fetchUserRole = () => {
    //         const storedUserRole = localStorage.getItem("roles");
    //         if (storedUserRole) {
    //             setUserRole(String(storedUserRole));  // Ensure it's treated as a string
    //         } else {
    //             setUserRole('Guest');  // Default to 'Guest' if no role found
    //         }
    //     };

    //     fetchUserRole();

    //     const handleStorageChange = (event) => {
    //         if (event.key === "roles") {
    //             fetchUserRole();
    //         }
    //     };

    //     window.addEventListener("storage", handleStorageChange);
    //     return () => {
    //         window.removeEventListener("storage", handleStorageChange);
    //     };
    // }, []);




    return (
        <>
            <Helmet>
                <title>BizNet USA - Your Partner in Success</title>
                <meta
                    name="description"
                    content="Explore BizNet USA's professional services. Navigate through categories and subcategories seamlessly to find the service you need."
                />
                <meta
                    name="keywords"
                    content="BizNet USA, Categories, Subcategories, Professional Services"
                />
                <meta name="author" content="BizNet USA Team" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta property="og:title" content="BizNet USA" />
                <meta
                    property="og:description"
                    content="Browse through BizNet USA's extensive categories and subcategories of professional services."
                />
                <meta property="og:image" content={imgLogo} />
                <meta property="og:url" content="https://www.biznetusa.com" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="header-parent w-100">
                <header>
                    <nav className="navbar navbar-expand-lg bg-light p-md-3 p-0">
                        <div className="container-fluid ">
                            <div className="d-flex justify-content-between flex-row align-items-center small__div">
                                <div className="nav-item logo-nav">
                                    <Link className="navbar-brand text-danger fw-bold h1" to="/">
                                        <img src={imgLogo} width="80" alt="Logo" />
                                    </Link>
                                </div>

                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarNav"
                                    aria-controls="navbarNav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>

                            <div
                                className="collapse navbar-collapse justify-content-md-end gap-3"
                                id="navbarNav"
                            >
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link className="text-muted" to="/">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="text-muted" to="/About">
                                            About
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="text-muted" to="/Licenese-Verifications">
                                            License Verifications
                                        </Link>
                                    </li>

                                    {/* Dynamic Categories and Subcategories */}
                                    {categories.length > 0 ? (
                                        categories.map((category) => (
                                            <li className="nav-item dropdown" key={category.id}>
                                                <Link
                                                    className="nav-link text-start dropdown-toggle"
                                                    to={`/category/${category.id}`}
                                                    id={`navbarDropdown-${category.id}`}
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    {category.cat_title}
                                                </Link>
                                                <ul
                                                    className="dropdown-menu"
                                                    aria-labelledby={`navbarDropdown-${category.id}`}
                                                >
                                                    <div className="dropdown-column">
                                                        <div className="dropdown-grid">
                                                            {category.sub_categories &&
                                                                category.sub_categories.length > 0 ? (
                                                                category.sub_categories.map((subCategory) => (
                                                                    <li key={subCategory.id}>
                                                                        <Link
                                                                            className="dropdown-item"
                                                                            to={`/${subCategory.sub_title.replace(
                                                                                /\s+/g,
                                                                                ""
                                                                            )}/${subCategory.id}`} // Generate URL based on title and ID
                                                                        >
                                                                            {subCategory.sub_title}
                                                                        </Link>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <li>
                                                                    <span className="dropdown-item">
                                                                        No Subcategories
                                                                    </span>
                                                                </li>
                                                            )}
                                                        </div>
                                                    </div>
                                                </ul>
                                            </li>
                                        ))
                                    ) : (
                                        <li>No categories available.</li>
                                    )}

                                    <li className="nav-item">
                                        <Link className="text-muted" to="/HowtoWork">
                                            How It Works
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="text-muted" to="/Advertise">
                                            Advertise
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="text-muted" to="/Contact">
                                            Contact
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="text-muted" to="Start-chat-with-znet">
                                            Notifications
                                        </Link>
                                    </li>
                                </ul>

                                {/* Right Side (User Display or Login/Signup) */}
                                <div className="d-flex Listing_Details align-items-center ">
                                    {!isAuthenticated ? (
                                        <Link to="/Login">
                                            <button className="btn me-2 ">Login / SignUp</button>
                                        </Link>
                                    ) : (
                                        <li className="nav-item dropdown ">
                                            <Link
                                                className="nav-link"
                                                to="#"
                                                id="userDropdown"
                                                role="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <button className="btn me-2 ">{userName}</button>
                                            </Link>
                                            <ul
                                                className="dropdown-menu p-2 mt-2 two-column-dropdown"
                                                aria-labelledby="userDropdown"
                                            >
                                                <div className="dropdown-column">
                                                    <div className="dropdown-grid">
                                                        {userRole == 1 && (
                                                            <>
                                                                <li>
                                                                    <Link to="/Favorites">
                                                                        <span className="dropdown-item text-dark">
                                                                            Favorites
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/SavedSearches">
                                                                        <span className="dropdown-item text-dark">
                                                                            Saved Searches
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/OpenHouseSchedule">
                                                                        <span className="dropdown-item text-dark">
                                                                            Open House Schedule
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/Appointments">
                                                                        <span className="dropdown-item text-dark">
                                                                            Appointments
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/Agent">
                                                                        <span className="dropdown-item text-dark">
                                                                            Your Agent
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/Offer">
                                                                        <span className="dropdown-item text-dark">
                                                                            Offers
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/Review">
                                                                        <span className="dropdown-item text-dark">
                                                                            Reviews
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/OwnerDashboard">
                                                                        <span className="dropdown-item text-dark">
                                                                            Owner Dashboard
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/AccountSettings">
                                                                        <span className="dropdown-item text-dark">
                                                                            Account Settings
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/UserSetting">
                                                                        <span className="dropdown-item text-dark">
                                                                            User Setting
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                            </>
                                                        )}

                                                        {userRole == 2 && (
                                                            <>
                                                                <li>
                                                                    <Link to="/SellerProduct">
                                                                        <span className="dropdown-item text-dark">
                                                                            Home
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/AllTeam">
                                                                        <span className="dropdown-item text-dark">
                                                                            All Team
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/AllJob">
                                                                        <span className="dropdown-item text-dark">
                                                                            All Job
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/CareerAgent">
                                                                        <span className="dropdown-item text-dark">
                                                                            Career Agent
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/JoinAgent">
                                                                        <span className="dropdown-item text-dark">
                                                                            Join Agent
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/AccountSettingCandidate">
                                                                        <span className="dropdown-item text-dark">
                                                                            Account Setting Candidate
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/AssociateAgentIndependent">
                                                                        <span className="dropdown-item text-dark">
                                                                            Associate Agent Independent
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/CandidateHome">
                                                                        <span className="dropdown-item text-dark">
                                                                            Candidate Home
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/HVHApply">
                                                                        <span className="dropdown-item text-dark">
                                                                            HVH Apply
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/AgentThanks">
                                                                        <span className="dropdown-item text-dark">
                                                                            Agent Thanks
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/MultiStepForm">
                                                                        <span className="dropdown-item text-dark">
                                                                            Multi-Step Form
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/SuccessfulApply">
                                                                        <span className="dropdown-item text-dark">
                                                                            Successful Apply
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/JobAlert">
                                                                        <span className="dropdown-item text-dark">
                                                                            Job Alert
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/JoinTalentCommunity">
                                                                        <span className="dropdown-item text-dark">
                                                                            Join Talent Community
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/SaveJob">
                                                                        <span className="dropdown-item text-dark">
                                                                            Save Job
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                            </>
                                                        )}

                                                        {userRole == 10 && (
                                                            <>
                                                                <li>
                                                                    <Link to="/Agent">
                                                                        <span className="dropdown-item text-dark">
                                                                            Your Agent
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/AccountSettingCandidate">
                                                                        <span className="dropdown-item text-dark">
                                                                            Account Setting Candidate
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                            </>
                                                        )}

                                                        {userRole == 14 && (
                                                            <>
                                                                <li>
                                                                    <Link to="/ManageServiceContractor">
                                                                        <span className="dropdown-item text-dark">
                                                                            ManageServiceContractor
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/ContractorProduct">
                                                                        <span className="dropdown-item text-dark">
                                                                            Contractor Product
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/TaskProgress">
                                                                        <span className="dropdown-item text-dark">
                                                                            Task Progress
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/ScheduleProject">
                                                                        <span className="dropdown-item text-dark">
                                                                            Schedule Project
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/ReviewManagement">
                                                                        <span className="dropdown-item text-dark">
                                                                            Review Management
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/ProjectInquiries">
                                                                        <span className="dropdown-item text-dark">
                                                                            Project Inquiries
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/ClientCommunication">
                                                                        <span className="dropdown-item text-dark">
                                                                            Client Communication
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                            </>
                                                        )}

                                                        {userRole == 15 && (
                                                            <>
                                                                <li>
                                                                    <Link to="/RealtorPanel">
                                                                        <span className="dropdown-item text-dark">
                                                                            Realtor Panel
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                            </>
                                                        )}

                                                        <li>
                                                            <span
                                                                className="dropdown-item text-dark"
                                                                onClick={handleLogout}
                                                            >
                                                                Logout
                                                            </span>
                                                        </li>
                                                    </div>
                                                </div>
                                            </ul>
                                        </li>
                                    )}
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
            </div>
        </>
    );
};

export default Header;
