import React, { useEffect, useState } from "react";
import MainCards from "../../components/MainCards";
import HomeBannerCard from "../../components/HomeBannerCard";
import img1 from "../../assets/images/real estate _1.jpg";
import img2 from "../../assets/images/real estate _10.jpg";
import img3 from "../../assets/images/real estate _11.jpg";
import logo from "../../assets/favicon/znet.jpg";
import CityRealEstateList from "../../components/CityRealEstateList";
import RealEstateTabs from "../../components/RealEstateTabs";
import RentByCityList from "../../components/RentByCityList";
import Footer from "../../components/Footer";
import Header from "../../components/header";
import TaglineHeader from "../../components/TaglineHeader";
import Testimonial from "../../components/Testimonial";
import PurposeSection from "../../components/PurposeSection";
import ProcessSection from "../../components/ProcessSection";
import WhyZnet from "../../components/WhyZnet";
import AppartementsRealEstate from "../../components/AppartementsRealEstate";
import GetLocation from "../../components/ProductByLocation";
import UserProfiling from "../../components/UserProfiling";
import { ToastContainer } from "react-toastify";
import Recommendations from "../../components/Recommandation";
import { Helmet } from "react-helmet";
import UpcomingEvents from "../../components/UpcomingEvents";
import ExecutiveLeaders from "../../components/ExecutiveLeaders";
import SuccessStories from "../../components/SuccessStories";
import CharacterCard from "../../components/CharacterCard";
import CEO from "../../assets/images/ceo.jpg";
import COO from "../../assets/images/DJ jr Eschbach.jpg";
import Nalley from "../../assets/images/Jash Nalley.jpg";
import Sir from "../../assets/images/sir.jpg";

const Home = () => {
    const propertyImages = [img1, img2, img3];
    const [showModal, setShowModal] = useState(false);
    const [userRole, setUserRole] = useState("Guest");
    const [userId, setUserId] = useState(null);
    const fetchUserRole = () => {
        const storedUserRole = localStorage.getItem("roles");
        setUserRole(storedUserRole || "Guest");
    };

    const fetchUserId = () => {
        const storedUserId = localStorage.getItem("user_id");
        setUserId(storedUserId || null);
    };

    useEffect(() => {
        fetchUserRole();
        fetchUserId();

        const handleStorageChange = (event) => {
            if (event.key === "roles") {
                fetchUserRole();
            }
        };

        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    const characters = [
        {
            name: "Zhara Michelle York",
            title: "Founder and CEO",
            description:
                "Zhara York, Founder & CEO of ZNet, is transforming real estate by connecting industry professionals through technology-driven networking and tailored solutions. With expertise in real estate, technology, and business, she fosters collaboration and empowers users with insights, redefining success—one connection at a time.",
            image: [CEO],
        },
        {
            name: "DJ jr Eschbach",
            title: "Co-Founder and COO",
            description:
                "DJ jr Eschbach is the Co-Founder and COO of ZNet, a cutting-edge real estate platform. As the visionary behind the platform, He leads its business strategy and operations, driving key market initiatives and innovative solutions. DJ’s passion for real estate and technology powers ZNet’s mission to provide seamless services to movers, agents, and industry professionals across both residential and commercial sectors",
            image: [COO],
        },
        {
            name: "Jash Nalley",
            title: "Financial Consultant",
            description:
                "Joshua Layne is a financial services expert specializing in life insurance, 401k/IRA rollovers, and long-term care. Licensed in 18 states, he partners with top institutions to create wealth protection strategies. Known for simplifying complex financial concepts, Joshua works with a fully independent agency, offering tailored solutions from 192 leading companies across the U.S., Puerto Rico, and Canada.",
            image: [Nalley],
        },
        {
            name: "Eng. Muhammad Fayyaz",
            title: "CEO & Lead Development",
            description:
                "Eng. Muhammad Fayyaz is the CEO of Codesinc and a skilled web developer. Passionate about technology and innovation, he leads a team that creates cutting-edge websites and web solutions. Fayyaz specializes in full-stack development and custom web solutions, helping Codesinc push the limits of web development to deliver smooth, user-friendly experiences.",
            image: [Sir],
        },
    ];

    return (
        <>
            <Helmet>
                <title>Znet Real Estate Home</title>
                <meta
                    name="description"
                    content="Explore top properties in prime locations with Znet Real Estate. Find your dream home or apartment in Chevy Chase and surrounding areas today."
                />
                <meta
                    name="keywords"
                    content="real estate, Znet, homes for sale, apartments for rent, Chevy Chase, luxury properties, real estate listings"
                />
                <meta property="og:title" content="Znet Real Estate Home" />
                <meta
                    property="og:description"
                    content="Discover and explore top properties available in Chevy Chase and other prime locations with Znet Real Estate. Begin your journey home with us."
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={logo} />
                <meta property="twitter:image" content={logo} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Znet Real Estate Home" />
                <meta
                    name="twitter:description"
                    content="Find your dream property in prime locations like Chevy Chase with Znet Real Estate. Check out our premier listings and make an informed choice."
                />
                <meta name="twitter:image" content={logo} />
            </Helmet>
            <UserProfiling
                userRole={userRole}
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <ToastContainer />
            <TaglineHeader />
            <Header />
            <main className="Main_portion">
                {userId ? (
                    <div className="container-fluid px-4 pb-4 g-0 rounded bg-light">
                        <div id="anas__pava" className="d-flex">
                            <RealEstateTabs />
                            <HomeBannerCard
                                images={propertyImages}
                                location="Chevy Chase, MD"
                                price="$3,599,000"
                                title="Znet PREMIER LISTING"
                            />
                        </div>
                    </div>
                ) : (
                    <HomeBannerCard isLarge={true} />
                )}
                {userId ? (
                    <>
                        <MainCards />
                        <GetLocation />
                    </>
                ) : (
                    <Recommendations />
                )}
                {!userId && (
                    <>
                        <PurposeSection />
                        <ProcessSection />
                        <WhyZnet />
                        <Testimonial />
                        <SuccessStories />
                    </>
                )}
                <UpcomingEvents />
                {/* <ExecutiveLeaders /> */}
                <div className="container">
                    <h3 className="text-center mb-5 display-5 fw-bold">
                        Executive Leadership
                    </h3>
                    <div className="row">
                        {characters.map((character, index) => (
                            <div
                                key={index}
                                className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 g-0 p-0"
                            >
                                <CharacterCard
                                    name={character.name}
                                    title={character.title}
                                    description={character.description}
                                    image={character.image}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </main>

            <div className="container py-4">
                <h3 className="m-3">Search for homes by city</h3>
                <CityRealEstateList />
                <hr className="my-5" />
                <h3 className="m-3">Search for apartments by city</h3>
                <AppartementsRealEstate />
                <hr className="my-5" />
                <h3 className="m-3">Search for houses for rent by city</h3>
                <RentByCityList />
            </div>


            <Footer />
        </>
    );
};

export default Home;
