// ChatPage.js
import React, { useEffect, useState } from "react";
import Chat from "../../components/Chat";
import UsersList from "../../components/UsersList";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/Footer";
import connectSocket from "../../SocketContext";
import axios from "axios";

const ChatPage = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [storedId, setStoredID] = useState(null);

    const handleSelectUser = (user) => {
        setSelectedUser(user);
        setStoredID(!user.id ? user.user_id : user.id)
    };


    useEffect(() => {
        const storedUserId = localStorage.getItem("user_id");
        const storedUserName = localStorage.getItem("user_name");
        const chatUser = { id: parseInt(storedUserId), name: storedUserName }
        if (storedUserId) {
            setCurrentUser(chatUser);
        }
    }, []);



    return (
        <>
            <Header />
            <connectSocket>
                <Container fluid className="py-4">
                    <Row>
                        {/* Sidebar for Users List */}
                        <Col xs={12} md={3} className="mb-3">
                            <Card className="h-100 shadow">
                                <Card.Header className="text-white text-center" style={{ backgroundColor: "var(--background_color)" }}>
                                    <h5>Users</h5>
                                </Card.Header>
                                <Card.Body>
                                    <UsersList onSelectUser={handleSelectUser} />
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Chat Window */}
                        <Col xs={12} md={9} className="mb-3">
                            <Card className="h-100 shadow">
                                <Card.Header className="text-white" style={{ backgroundColor: "var(--background_color)" }}>
                                    {selectedUser ? (
                                        <h5>Chatting with {selectedUser.name}</h5>
                                    ) : (
                                        <h5>Please select a user</h5>
                                    )}
                                </Card.Header>
                                <Card.Body className="d-flex flex-column justify-content-between bg-light">
                                    {currentUser && selectedUser ? (
                                        <Chat senderId={currentUser.id} receiverId={storedId} />
                                    ) : (
                                        <div className="text-center text-muted my-5">
                                            <p className="fs-4">No user selected. Select a user to start chatting.</p>
                                            <Button variant="primary" onClick={() => console.log("Select a user")}>Refresh Users</Button>
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </connectSocket>
            <Footer />
        </>
    );
};

export default ChatPage;
