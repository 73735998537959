import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const WhatsNewSection = () => {
    const [newsItems, setNewsItems] = useState([]);
    const [imagePath, setImagePath] = useState("");
    const navigate = useNavigate();

    const handleReadMore = (id) => {
        navigate(`/blog/${id}`);
    };

    useEffect(() => {
        const fetchLatestBlogs = async () => {
            try {
                const response = await fetch("https://api.biznetusa.com/api/get-latestblog");
                const data = await response.json();
                if (data.status === 200) {
                    setNewsItems(data.blogs);
                    setImagePath(data.imagePath);
                }
            } catch (error) {
            }
        };

        fetchLatestBlogs();
    }, []);

    return (
        <>
         <Helmet>
                <meta charSet="utf-8" />
                <title>What's New | BizNet USA</title>
                <meta name="description" content="Stay updated with the latest blogs and news from BizNet USA." />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="What's New | BizNet USA" />
                <meta property="og:description" content="Stay updated with the latest blogs and news from BizNet USA." />
                <meta property="og:image" content="https://via.placeholder.com/150" />
                <meta property="og:url" content="https://www.biznetusa.com/whats-new" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="What's New | BizNet USA" />
                <meta name="twitter:description" content="Stay updated with the latest blogs and news from BizNet USA." />
                <meta name="twitter:image" content="https://via.placeholder.com/150" />
            </Helmet>
        <Container>
            <h4 className="mb-4">What's New</h4>
            <hr className="mb-4" />
            <Row className="gy-4">
                {newsItems.map((newsItem, index) => (
                    <Col md={12} key={index}>
                        <Card className="border-0 h-100">
                            <Row className="g-0 align-items-center">
                                <Col xs={4}>
                                    <Card.Img
                                        src={
                                            newsItem.images && newsItem.images.length > 0
                                                ? `${imagePath}/${newsItem.images[0].image}`
                                                : "https://via.placeholder.com/150"
                                        }
                                        alt={newsItem.title}
                                        className="rounded"
                                        onClick={() => handleReadMore(newsItem.id)}
                                    />
                                </Col>
                                <Col xs={8}>
                                    <Card.Body>
                                        <Card.Title className="fs-6">
                                            {newsItem.title}
                                        </Card.Title>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
        </>
    );
};

export default WhatsNewSection;
