/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ListGroup, Form, Badge, Button } from 'react-bootstrap';
import './UsersList.css';

const UsersList = ({ onSelectUser }) => {
    const [isActive, setIsActive] = useState(false);
    const [users, setUsers] = useState([]);
    const [similarUsers, setSimilarUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [filterUsers, setFilterUsers] = useState([]);
    const userId = localStorage.getItem('user_id');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [allUsersRes, similarUsersRes] = await Promise.all([
                axios.get("https://api.biznetusa.com/api/all-users"),
                axios.get(`https://api.biznetusa.com/api/getsimilarusers/${userId}`),
            ]);

            if (allUsersRes.data.status === 200) {
                const allUsers = allUsersRes.data.allusers;
                setUsers(allUsers);
                setFilterUsers(allUsers);
            }


            if (similarUsersRes.data.similar_users) {
                const similarUsersData = similarUsersRes.data.similar_users.map((user) => ({
                    ...user,
                    unread: 0,
                }));
                setSimilarUsers(similarUsersData);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleUserClick = async (user) => {
        const userIdField = isActive ? user.id : user.user_id;
        onSelectUser(user);

        try {
            await axios.post('https://api.biznetusa.com/api/mark-messages-read', {
                senderId: userId,
                receiverId: userIdField,
            });

            if (isActive) {
                setUsers((prevUsers) =>
                    prevUsers.map((u) =>
                        u.id === user.id ? { ...u, unread: 0 } : u
                    )
                );
            } else {
                setSimilarUsers((prevSimilarUsers) =>
                    prevSimilarUsers.map((u) =>
                        u.user_id === user.user_id ? { ...u, unread: 0 } : u
                    )
                );
            }

            setFilterUsers((prevFilterUsers) =>
                prevFilterUsers.map((u) =>
                    (isActive ? u.id === user.id : u.user_id === user.user_id)
                        ? { ...u, unread: 0 }
                        : u
                )
            );
        } catch (err) {
            console.error('Failed to mark messages as read:', err);
        }
    };

    const fetchUnreadCounts = async () => {
        try {
            const updatedUsers = await Promise.all(
                users.map(async (user) => {
                    const unreadCount = await handleNotificationUpdate(userId, user.id);
                    return { ...user, unread: unreadCount };
                })
            );
            setUsers(updatedUsers);
            setFilterUsers(updatedUsers);
        } catch (error) {
            console.error("Error fetching unread counts:", error);
        }
    };

    const handleNotificationUpdate = async (senderId, receiverId) => {
        try {
            const response = await axios.get(
                `
https://api.biznetusa.com/api/unread-messages/${senderId}/${receiverId}
`
            );
            return response.data.unread_messages || 0;
        } catch (err) {
            console.error(`Error fetching unread messages for senderId: ${senderId}, receiverId: ${receiverId}`);
            return 0;
        }
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearch(query);

        const filtered = (isActive ? users : similarUsers).filter((user) =>
            user.name.toLowerCase().includes(query)
        );
        setFilterUsers(filtered);
    };

    const handleToggleUsers = (active) => {
        setIsActive(active);
        setFilterUsers(active ? users : similarUsers);
    };

    return (
        <div>
            <Form.Control
                type="text"
                name="searchUsers"
                id="searchUsers"
                placeholder="Enter User Name"
                className="mb-2 p-2"
                value={search}
                onChange={handleSearch}
            />
            <div>
                <Button
                    type="button"
                    onClick={() => handleToggleUsers(true)}
                    className={isActive ? "active" : ""}
                >
                    Users
                </Button>
                <Button
                    type="button"
                    onClick={() => handleToggleUsers(false)}
                    className={!isActive ? "active" : ""}
                >
                    Similar
                </Button>
            </div>
            <ListGroup className="user-list">
                {filterUsers.map((user) => (
                    <ListGroup.Item
                        key={isActive ? user.id : user.user_id}
                        action
                        onClick={() => handleUserClick(user)}
                        className="user-list-item d-flex justify-content-between"
                    >
                        {user.name}
                        {user.unread > 0 && (
                            <Badge bg="danger" className="ms-2">
                                {user.unread}
                            </Badge>
                        )}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    );
};

export default UsersList;

